import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import "./index.css"

const columns = [
  { field: 'id', headerName: 'ID'},
  { field: 'billid', headerName: 'Bill Id'},
  { field: 'date', headerName: 'Date'},
  {
    field: 'status',
    headerName: 'Status'
  },
  {
    field: 'customer',
    headerName: 'Name'
  },
  {
    field: 'email',
    headerName: 'E-mail'
  },{
    field: 'country',
    headerName: 'Country'
  },{
    field: 'shipping',
    headerName: 'Shipping'
  },{
    field: 'source',
    headerName: 'Source'
  },{
    field: 'ordertype',
    headerName: 'OrderType'
  },
];

const rows = [
  { id: 1, billid: '12333', date: "22 jan 2023",status:"deliverd",customer:"krish" ,email:"krish@gmail",country:"india",shipping:"authorise shop",source:"shopify",ordertype:"customer"},
  { id: 2, billid: '12333', date: "22 jan 2023",status:"pending",customer:"ahmed" ,email:"abc@gmail",country:"india",shipping:"authorise shop",source:"shopify",ordertype:"customer"},
  { id: 3, billid: '12333', date: "22 jan 2023",status:"pending",customer:"ahmed" ,email:"edward@gmail",country:"india",shipping:"authorise shop",source:"shopify",ordertype:"customer"},
  { id: 4, billid: '12333', date: "22 jan 2023",status:"dispatched",customer:"ediibor" ,email:"abc@gmail",country:"india",shipping:"authorise shop",source:"shopify",ordertype:"customer"},
  { id: 5, billid: '12333', date: "22 jan 2023",status:"pending",customer:"ahmed" ,email:"abc@gmail",country:"india",shipping:"authorise shop",source:"shopify",ordertype:"customer"},
  { id: 6, billid: '12333', date: "22 jan 2023",status:"dispatched",customer:"antony" ,email:"abc@gmail",country:"india",shipping:"authorise shop",source:"shopify",ordertype:"customer"},
  { id: 7, billid: '12333', date: "22 jan 2023",status:"pending",customer:"ahmed" ,email:"abc@gmail",country:"india",shipping:"authorise shop",source:"shopify",ordertype:"customer"},
  { id: 8, billid: '12333', date: "22 jan 2023",status:"dispatched",customer:"ahmed" ,email:"abc@gmail",country:"india",shipping:"authorise shop",source:"shopify",ordertype:"customer"},
  { id: 9, billid: '12333', date: "22 jan 2023",status:"pending",customer:"ahmed" ,email:"abc@gmail",country:"india",shipping:"authorise shop",source:"shopify",ordertype:"customer"},
  { id: 10, billid: '12333', date: "22 jan 2023",status:"deliverd",customer:"ahmed" ,email:"abc@gmail",country:"india",shipping:"authorise shop",source:"shopify",ordertype:"customer"},
  { id: 11, billid: '12333', date: "22 jan 2023",status:"pending",customer:"ahmed" ,email:"abc@gmail",country:"india",shipping:"authorise shop",source:"shopify",ordertype:"customer"},
  { id: 12, billid: '12333', date: "22 jan 2023",status:"pending",customer:"ahmed" ,email:"abc@gmail",country:"india",shipping:"authorise shop",source:"shopify",ordertype:"customer"},
  { id: 13, billid: '12333', date: "22 jan 2023",status:"pending",customer:"ahmed" ,email:"abc@gmail",country:"india",shipping:"authorise shop",source:"shopify",ordertype:"customer"},
  { id: 14, billid: '12333', date: "22 jan 2023",status:"pending",customer:"ahmed" ,email:"abc@gmail",country:"india",shipping:"authorise shop",source:"shopify",ordertype:"customer"},
];
      


export default function DataTable({searchParam}) {
  const [selectedRows,setSelectedRows]=React.useState("")
    
  const handleRowSelection = (newSelection) => {
    setSelectedRows(newSelection.rows);
    console.log(selectedRows)
  };

    let rowsData=rows.filter((val)=>val[searchParam.category].match("^"+searchParam.keyWord)&&(val.status==searchParam.status||searchParam.status==""))
  return (
    <div style={{ flex:1, width: '100%' ,padding:"1rem",boxSizing:"border-box",display:"flex",flexDirection:"column"}}>
     <div style={{backgroundColor:"white",display:"flex",justifyContent:"space-between",alignItems:"center",padding:"0 1rem",boxSizing:'border-box',borderRadius:".5rem .5rem 0 0",borderTop:"1px solid grey"}}>
         <h1 style={{padding:"1rem",boxSizing:"border-box",display:"inline-block"}}>Product Summary</h1>
         <button className='butt'>dispatch selected</button>
     </div>
     <div style={{flex:1}}>
      <DataGrid
        rows={rowsData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 6 },
          },
        }}
        pageSizeOptions={[6,10,20,30]}
        checkboxSelection
        onSelectionModelChange={handleRowSelection}        
      />
      </div>
    </div>
  );
}