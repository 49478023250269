import React from "react";
import './header.css';


function Header({setSearchParam,searchParam}){
      return (
        <div style={{padding:"15px",boxSizing:"border-box"}}>
        <div className="header-container" >
        <h1 className="h1" style={{padding:"0px",margin:"0px"}}>Orders</h1>
        <button>create new</button>
        </div>
        <div className="search-container">
            <div className="part-1 parts">
                <h3>what are you looking for?</h3>
                <input type="text" placeholder="search for catogory,name,company,etc" value={searchParam.keyWord} onChange={(event)=>setSearchParam((state)=>({...state,["keyWord"]:event.target.value}))}/>
            </div>
            <div className="part-2 parts">
                <h3>category</h3>
                <select name="" id="" value={searchParam.category} onChange={(e)=>setSearchParam((state)=>({...state,["category"]:e.target.value}))}>
                    <option value="customer">Name</option>
                    <option value="email">E mail</option>
                    <option value="country">Country</option>
                </select>
            </div>
            <div className="part-3 parts">
                <h3>status</h3>
                <select name="" id="" value={searchParam.status} onChange={(event)=>{setSearchParam((state)=>({...state,["status"]:event.target.value}))}}>
                    <option value="">all</option>
                    <option value="pending">pending</option>
                    <option value="dispatched">dispatched</option>
                    <option value="deliverd"> deliverd</option>
                </select>
            </div>
            <div className="part-4 parts">
                <button>search</button>
            </div>
        </div>
        </div>
      )
}

export default Header