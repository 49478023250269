import { useState } from 'react';
import './App.css';
import Header from './Components/Header';
import DataTable from './Components/Table';

function App() {
  const [searchParam,setSearchParam]=useState({
    keyWord:"",
    status:"",
    category:"customer"
  })
  console.log(searchParam)
  return (
    <div style={{display:"flex",flexDirection:"column",height:"100vh",width:"99vw"}}>
    <Header setSearchParam={setSearchParam} searchParam={searchParam}/>
    <DataTable searchParam={searchParam}/>
    </div>
  );
}

export default App;
